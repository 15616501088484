import React from 'react';
import './Initials.css';
import NavButtons from '../NavButtons';

export default function Initials() {
    return (
        <>
        <div className="initials-container">
           
        <NavButtons />
        </div>
        </>
    )
}